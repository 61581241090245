import { Component } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-routing-machine';
import { icon, Marker } from 'leaflet';
import { Inject, Input, OnInit } from '@angular/core';
import { CadastroService } from './cadastro.service';

export const DEFAULT_LAT = 46.07162336624547;
export const DEFAULT_LON = 25.042052205802907;
export const TITULO = 'Proyecto';
const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public title: string = 'Cadastro';
  public trees: Array<any> = [];

  private map: any;

  constructor(private cadastroService: CadastroService) {
  }

  ngOnInit(): void {
    this.cadastroService.getTrees()?.then((trees) => {
      this.trees = trees;
      this.initMap(this.trees);
    });
  }

  public showOnMap(tree: any) {
    this.map.panTo(new L.LatLng(tree.xCoord, tree.yCoord));
    setTimeout(() => {
      this.map.setZoom(14);
    }, 600)
  }

  private initMap(trees: Array<any>): void {
    //configuración del mapa
    this.map = L.map('map', {
      center: [46.07162336624547, 25.042052205802907],
      attributionControl: false,
      zoom: 7
    });

    //iconos personalizados
    const iconDefault = L.icon({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41]
    });
    L.Marker.prototype.options.icon = iconDefault;

    //titulo
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: ''
    });

    //marca con pop up
    trees.forEach((tree: any) => {
      const lat = tree.xCoord;
      const lon = tree.yCoord;
      const marker = L.marker([lat, lon]).bindPopup(tree.title);
      marker.addTo(this.map);
    });

    //marca forma de circulo
    // const mark = L.circleMarker([this.lat, this.lon]).addTo(this.map);
    // mark.addTo(this.map);


    //ruta
    /*L.Routing.control({
      router: L.Routing.osrmv1({
        serviceUrl: `https://router.project-osrm.org/route/v1/`
      }),
      showAlternatives: true,
      fitSelectedRoutes: false,
      show: false,
      routeWhileDragging: true,
      waypoints: [
        L.latLng(this.lat, this.lon),
        L.latLng(lat, lon)
      ]
    }).addTo(this.map);*/
    tiles.addTo(this.map);
  }
}
