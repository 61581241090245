import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CadastroService {

  url:string = 'https://service.cadastru-verde.ro';
  //url: string = 'http://localhost:3000';
  session: any = null;

  constructor(private http: HttpClient) { }

  getTrees() {
    return this.http.get<any>(this.url + '/cadastro/trees').toPromise().then((treesList) => {
      return treesList;
    }).catch((err) => {
      throw err;
    });
  }

}
