<header>
  <div class="wrapper">
    <div class="logo-name">
      <img src="/assets/images/Logo.png">
      <span>CADASTRU-VERDE</span>
    </div>
  </div>
</header>

<main>
  <div class="wrapper">
    <div class="description">
      <p class="title">CONTABILITATEA CADASTRALĂ A PLANTELOR VERZI</p>
      <p class="text">Cadastru-verde.ro are drept scop întocmirea unei baze de date complexe destinată păstrării unei evidențe reale și totodată gestionării eficiente a spațiilor verzi de pe întreg teritoriul intravilan urban. Constituirea bazei de date ca un sistem informațional presupune inventarierea terenurilor ocupate de spații verzi, evidențierea tipului de proprietate și modul de administrare a acestor terenuri, precum și descrierea caracteristicilor cantitative și calitative ale vegetației de pe aceste terenuri</p>
      <p class="text">Cadastru-verde.ro reprezintă un punct de reper în dezvoltarea durabilă a aglomerărilor urbane având drept obiectiv imediat eficientizarea acțiunilor întreprinse în asigurarea unui mediu sănătos</p>
    </div>

    <div class="content">

      <div class="sector-left">
        <div class="search-bar">

        </div>
        <div class="categories">
          <div class="category tree opened">
            <div class="category-title">
              <img class="category-icon" src="/assets/icons/trees.png">
              <span class="title">Arbori</span>
              <!--<img class="drop-icon" src="/assets/icons/down-active.png">-->
            </div>
            <div class="items">
              <div *ngFor="let tree of trees" class="item tree" (click)="showOnMap(tree)">
                <div>
                  <span class="name" title="{{tree.title}}">{{tree.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sector-right">
        <div class="map-container">
          <div class="map-frame">
            <div id="map"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</main>

<footer>
  <div class="wrapper">
    <div>
      <div class="copyright">Copyright © 2024<br>Design by Ellit</div>
    </div>
    <div>
      <div class="logo-name">
        <img src="/assets/images/Logo.png">
        <span>CADASTRU-VERDE</span>
      </div>
    </div>
    <div></div>
  </div>
</footer>